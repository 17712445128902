@import 'fonts';

// gradients:
$darkBlueGradient: linear-gradient(90deg, #002645 0%, #003c6f 113.05%);
$blueGradient: linear-gradient(90.05deg, #1c75bc 17.39%, #004d8a 98.79%);

// colors:
$darkBlue: #002c50;
$semidarkBlue: #074f86;
$blue: #1d76bb;
$lightBlue: #43a2ee;
$ultralightBlue: #a7d2f5;
$white: #ffffff;
$backgroundWhite: #fcfcfc;
$gray-50: #e9eef5;
$gray-100: #d5dde9;
$gray-200: #9caabf;
$gray-300: #778498;
$gray-400: #515c6b;
$gray-500: #394353;
$gray-600: #28303d;
$black: #242a35;
