@import '_vars';

.homeContainer {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
}

.container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0 80px;
  justify-content: space-between;
  column-gap: 64px;
}

.articlesHeader {
  color: $blue;
  margin-top: 40px;
  @extend .semiboldH4;
}

.articles {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.showMoreNews {
  outline: none;
  border: none;
  background: transparent;
  text-transform: uppercase;
  color: $blue;
  @extend .semiboldP2;
  cursor: pointer;
  align-self: flex-end;
  display: flex;
  align-items: center;
  img {
    transform: rotate(-270deg);
    margin-left: 8px;
  }
}

@media screen and (max-width: 1365px) {
  .container {
    padding: 0 16px;
    column-gap: 32px;
  }

  .articles {
    width: 65%;
  }
}

@media screen and (max-width: 979px) {
  .container {
    flex-direction: column;
    padding: 0;
  }
  .articles {
    margin-bottom: 32px;
    display: flex;
    width: 100%;
    padding: 0 16px;
    align-items: center;
  }
  .articlesHeader {
    margin-top: 24px;
    font-size: 24px;
    line-height: 32px;
    align-self: flex-start;
  }
}
