@import '_vars';

.carouselContainer {
  width: 100vw;
  height: 748px;
  margin-top: 80px;
  position: relative;
}

.photoContainer {
  position: relative;
  height: 840px;
  max-width: 1440px;
}

.backgroundWrapper {
  width: 110vw;
  height: 105%;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    backdrop-filter: blur(20px);
    z-index: 1;
  }
}

.carouselTextWrapper {
  width: 100%;
  height: 100%;
  padding: 0 80px;
}

.carouselTextContainer {
  position: absolute;
  bottom: 88px;
  width: 800px;
  text-align: left;
  p:nth-child(2) {
    @extend .boldH3;
    margin: 16px 0;
  }
  p:last-child {
    @extend .regularP1;
  }
}

.carouselNewsText {
  color: white;
  cursor: pointer;
  transition: color 0.2s linear;
  &:hover,
  &:hover button {
    color: $ultralightBlue;
  }
}

.carouselNewsHeader {
  color: $white;
  cursor: pointer;
  transition: color 0.2s linear;
  &:hover {
    color: $lightBlue;
  }
}

.sideNewsContainer {
  position: absolute;
  bottom: 0;
  right: 80px;
  width: 416px;
  height: auto;
  max-height: 744px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  border-style: solid;
  border-image-slice: 1;
  border-width: 1px;
  padding-bottom: 28px;
  z-index: 10;
  border-image-source: linear-gradient(to bottom, white, black);
}

.sideNewsWrapper {
  width: 1440px;
  position: relative;
  margin: auto;
}

.sideNews {
  width: 100%;
  height: 100%;
  padding-top: 16px;

  img {
    transform: rotate(-90deg);
  }
}

.sideMenuLink {
  height: 96px;
  margin: 0 16px 8px 16px;
  padding: 8px 0 16px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.label {
  border-radius: 0;
  color: $white;
  @extend .regularP1;
  padding: 0 13px;
  height: 24px !important;
}

.labelBlue {
  background-color: $blue;
}

.labelDark {
  background: #28303d;
}

.linkText {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $white;
  cursor: pointer;
  position: relative;
  margin-top: 8px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > img {
    position: relative;
    right: 0;
    bottom: 0;
    align-self: flex-end;
  }
}

.gradientContainer {
  max-width: 1440px;
  height: 840px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 12.3%, #000000 90.99%);
  z-index: 1000;
}

@media screen and (min-width: 0) and (max-width: 600px) {
  .carouselTextContainer {
    position: absolute !important;
    left: 16px !important;
    width: 300px;
    padding-top: 0 !important;
    bottom: 72px !important;
    p:last-child {
      display: none;
    }
    p:nth-child(2) {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }
  .carouselContainer {
    width: 100%;
    height: 584px;
    margin-top: 56px;
  }
  .carouselTextContainer {
    padding-top: 500px;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .carouselTextWrapper {
    padding: 0 16px;
  }
  .carouselTextContainer {
    width: 600px;
  }
}

@media screen and (max-width: 1365px) {
  .sideNewsContainer {
    display: none;
  }
  .carouselTextWrapper {
    padding: 0 16px;
  }
  .carouselTextContainer {
    position: relative;
    padding-top: 370px;
    bottom: 0;
    left: 0;
  }
}

@media screen and (min-width: 1365px) and (max-width: 1440px) {
  .carouselTextContainer {
    width: 700px;
  }
}
